import { Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { uniqWith } from 'lodash'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs'
import { CandidateApi } from 'src/api'
import { useDebounce, useUnsubscribe } from 'src/hooks'
import { ICandidateModel } from 'src/interfaces'
import { PaginationService } from 'src/services'
import { UserUtils } from 'src/utils'

interface IProps extends Omit<
ComponentProps<typeof Select<number | number[], DefaultOptionType & {
  label: string
  row: ICandidateModel
}>>,
'showSearch' |
'optionFilterProp' |
'filterOption' |
'filterSort'
> {
  label?: keyof ICandidateModel
  tags?: boolean
  multiple?: boolean
}

export const SelectCandidate: FC<IProps> = ({
  label = 'userPhoneNo',
  tags,
  multiple,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const _paginationService = useMemo(() => new PaginationService<ICandidateModel>(CandidateApi), [])
  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [options, setOptions] = useState<IProps['options']>([])

  const rowsToOptions = useCallback((rows: ICandidateModel[]) => rows.map(
    row => ({
      row,
      value: row.id,
      label: String(
        label === 'fullName'
          ? UserUtils.getFullName(row)
          : typeof row[label] === 'string'
            ? row[label] || ''
            : ''
      ) || UserUtils.getFullName(row) || row.email || row.username || ' No Name'
    })
  ), [label])

  useDebounce(() => {
    _paginationService.paging({ keyword })
  }, 300, [keyword])

  useEffect(() => {
    _paginationService
      .loading$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value) => setLoading(value))
    _paginationService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(
        (data) => setOptions(
          (prev) => uniqWith(
            prev
              ? [...prev, ...rowsToOptions(data.rows)]
              : rowsToOptions(data.rows),
            (a, b) => a.value === b.value
          )
        )
      )
  }, [_paginationService, rowsToOptions, unsubscribe$])

  const mode = useMemo(() => props.mode ?? (
    multiple
      ? 'multiple'
      : tags
        ? 'tags'
        : undefined
  ), [props.mode, tags, multiple])

  return (
    <Select
      {...props}
      mode={mode}
      placeholder={props.placeholder || 'Select candidate'}
      loading={loading || props.loading}
      showSearch
      onSearch={(k) => k && setKeyword(() => k)}
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? '').includes(input) || (option?.row?.userPhoneNo ?? '').includes(input)}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
      options={options}
    />
  )
}
